<template>
    <div class="px-3 py-4 orders-wrapper">
        <div class="mb-4 d-flex align-center">
            <v-btn icon @click="$router.push({name: 'Home'})"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-spacer></v-spacer>
            <p class="mb-0 text-center font-weight-medium text-h6">{{$t("homeItemOrdersTitle")}}</p>
            <v-spacer></v-spacer>
        </div>
        <template v-if="!allOrders || allOrders.length == 0">
            <span class="mx-auto text-caption">No order found</span>
        </template>
        <template v-else>
            <div :key="`orders-page-${page}`">
                <div class="d-flex flex-column align-stretch orders-list-wrapper pb-2" :style="{'max-height': `calc(calc(var(--vh, 1vh) * 100) - ${headerHeight + 165}px)`}">
                    <div v-for="(order, key) in ordersToShow" :key="`order-${key}`" @click="handleOrderClick(order,key)">
                        <div class="rounded-lg my-2 px-3 py-2 full-width order-wrapper">
                            <div class="d-flex align-center full-width pt-2">
                                <div class="d-flex flex-column">
                                    <span class="text-caption">#{{order.orderNumber}}</span>
                                    <!-- <span class="grey--text text-caption">#{{order.orderNumber}}</span> -->
                                    <span class="grey--text text-caption">{{order.creationDate}}</span>

                                </div>
                                <v-spacer></v-spacer>
                                <div class="d-flex flex-column">
                                    <span class="text-subtitle-1 font-weight-bold primary--text">{{formatPrice(order.totalAmount)}}</span>
                                </div>
                            </div>
                            <v-icon class="custom-icon">mdi-chevron-down</v-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p class="text-caption font-italic font-weight-light">{{$tc("ordersTotalNb", numberOfOrders, {numberOfOrders} )}}</p>
                <v-pagination class="align-self-center px-3" v-model="page" :length="numberOfPages" total-visible></v-pagination>
            </div>
        </template>
        <!-- <div class="py-3 d-flex justify-center align-center orders-footer">
            <v-btn fab elevation="0" dark color="primary" width="64" height="64" @click="$router.push({ name: 'Home' })"> <v-img width="32" height="32" src="@/assets/img/icon-home-white.png" contain></v-img> </v-btn>
        </div> -->
    </div>
</template>

<script>
    import {pageInfoMixin} from "@/mixins/pageInfoMixin"
    export default {
        mixins: [pageInfoMixin],
        data() {
            return {
                page: 1,
                pageSize: 20
            }
        },
        async created(){
            this.showLoader({
                message: this.$t("getOrdersLoader"),
                color: this.$vuetify.theme.themes.light.primary
            })

            await this.getAllOrders(this.page - 1, this.pageSize)

            this.hideLoader()
            console.log("DLN orders", this.allOrders.content);
        },
        computed: {
            ordersToShow(){
                return this.allOrders.content
            },
            numberOfOrders(){
                return this.allOrders.totalElements
            },
            numberOfPages(){
                return this.allOrders.totalPages
            }
        },
        methods: {
            handleOrderClick(order, key){
                this.$router.push({name: "OrderDetails", params: {
                    orderComplexId: order.complexId,
                }, query: { keyIndex: key}})
            }
        },
        watch: {
            async page() {
                this.showLoader({
                    message: this.$t("getOrdersLoader"),
                    color: this.$vuetify.theme.themes.light.primary
                })
                
                await this.getAllOrders(this.page - 1, this.pageSize)

                this.hideLoader()
            }
        },
    }
</script>

<style scoped>
    .order-wrapper{
        border: solid 1px #80808040;
        height: 63px;
        position: relative;
    }
    .orders-wrapper{
        position: relative;
    }
    .orders-list-wrapper{
        overflow-y: auto;
    }
    .custom-icon{
        position: absolute;
        left: 50%;
        right: 50%;
        top: 32px;
    }
</style>
